import { CircularProgress } from '@mui/material'
import styles from "./index.module.scss"
import clsx from 'clsx'

type Props = {
    className?: string
}
const Spinner = (props: Props) => {
    const { className } = props;
    return (
        <div className={clsx(styles.loader, className)}>
            <CircularProgress />
        </div>
    )
}

export default Spinner;
