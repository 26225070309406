import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SortType } from "../../types";


export interface authState {
  search: string;
  sortValue: number | undefined;
  sortColumn: string | undefined;
  sortType: SortType;
}

const initialState: authState = {
  search: "",
  sortValue: 0,
  sortColumn: "",
  sortType: "DESC"
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<{ search: string }>) => {
      state.search = action.payload.search;
    },
    setSort: (state, action: PayloadAction<{ sort: number | undefined }>) => {
      state.sortValue = action.payload.sort;
    },
    resetSearch: (state) => {
      state.search = "";
    },
    setSortData: (state, action: PayloadAction<{ sortColumn?: string, sortType: SortType }>) => {
      state.sortColumn = action.payload.sortColumn;
      state.sortType = action.payload.sortType;
    },
  },
});

export const { setSearch, setSort, resetSearch, setSortData } = commonSlice.actions;

export default commonSlice.reducer;
