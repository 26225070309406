/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";
// import { User } from "../../types";

export interface AuthState {
  authenticated: boolean;
  isLoading: boolean;
  user?: User | null;
  token: string | null;
  refreshToken: string | null;
  email: string;
  isScan2FA: boolean;
  qrcode: string | null;
}

const token = localStorage.getItem("token");
const refreshToken = localStorage.getItem("refreshToken");

const initialState: AuthState = {
  authenticated: Boolean(token),
  isLoading: Boolean(token),
  email: "",
  isScan2FA: true,
  user: null,
  token: token,
  qrcode: "",
  refreshToken: refreshToken,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser: (
      state,
      action: PayloadAction<{ token: string; refreshToken: string }>
    ) => {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      state.isLoading = false;
      state.authenticated = true;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    logoutUser: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      state.authenticated = false;
      state.token = null;
      state.refreshToken = null;
      state.user = null;
      state.isLoading = false;
    },
    loadUser: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
      state.isLoading = false;
      return state;
    },
    setTokens: (
      state,
      action: PayloadAction<{ refreshToken: string; accessToken: string }>
    ) => {
      state.refreshToken = action.payload.refreshToken;
      state.token = action.payload.accessToken;
      localStorage.setItem("token", action.payload.accessToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);
      return state;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIs2FAScan: (state, action: PayloadAction<boolean>) => {
      state.isScan2FA = action.payload;
    },
    setQRCode: (state, action: PayloadAction<string>) => {
      state.qrcode = action.payload;
    },
    loadProfile: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
  },
});

export const {
  loginUser,
  logoutUser,
  loadUser,
  setEmail,
  setIs2FAScan,
  setQRCode,
  setTokens,
  loadProfile,
} = authSlice.actions;

export default authSlice.reducer;
