import { SetStateAction, useState } from 'react';

import styles from "./index.module.scss";
import CustomModal from '../common/CustomModal';
import Title from '../Title';
import Input from '../Input';
import FlexBox from '../common/FlexBox';
import CustomButton from '../CustomButton';
import { apiResponse } from '../../types';
import { useDisable2FAMutation } from '../../service/api';

type Props = {
    user: {
        email: string;
        id: string;
    }
    setResponse: React.Dispatch<SetStateAction<string>>;
}
const ForgotTwoFactor = (props: Props) => {
    const { user: { email, id }, setResponse } = props;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const toggle = () => setIsModalOpen(isModalOpen => !isModalOpen)
    const [disable2FA, { isLoading }] = useDisable2FAMutation();

    // const email = useAppSelector(state => state.auth.user?.email)
    const forgotHandler = async () => {
        if (!email) return;
        const payload = {
            data: {
                userType: "subadmin",
                email,
            },
            id
        };
        try {
            const { data, error } = (await disable2FA(payload)) as apiResponse;
            if (data) {
                toggle();
                setMessage("");
                setResponse(
                    "Your request to disable 2FA has been sent to the admin, Please try again after sometime."
                );
            }
            if (error) {
            }
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <div className={styles.container}>
            <CustomModal isOpen={isModalOpen} toggle={toggle}>
                <Title title="Contact admin" className={styles.title} />
                <Input
                    type='text'
                    id='email'
                    name='email'
                    placeholder='Email address'
                    value={email}
                    label='Email Address'
                    disabled
                />
                {/* <Input
                    type='textarea'
                    id='message'
                    name='message'
                    placeholder='Write here...'
                    value={message}
                    label='Message'
                    onChange={e => setMessage(e.target.value)}
                /> */}
                <FlexBox className={styles.flex}>
                    <CustomButton variant='outlined' onClick={toggle}>Cancel</CustomButton>
                    <CustomButton onClick={forgotHandler} loading={isLoading}>Send</CustomButton>
                </FlexBox>
            </CustomModal>
        </div>
    )
}

export default ForgotTwoFactor
