import style from './index.module.scss'
import { useNavigate } from 'react-router-dom'
import CustomModal from '../common/CustomModal'
import Title from '../Title'
import FlexBox from '../common/FlexBox'
import CustomButton from '../CustomButton'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../store/reducers/authSlice'

type Props = {
    isOpen: boolean;
    toggle: () => void;
}
const Logout = (props: Props) => {
    const { isOpen, toggle } = props;
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logoutHandler = () => {
        dispatch(logoutUser());
        navigate("/login")
    };
    return (
        <CustomModal isOpen={isOpen} toggle={toggle}>
            <Title className={style.title} title="Are you sure you want to logout?" />
            <FlexBox className={style.flex}>
                <CustomButton variant="outlined" onClick={toggle}>Cancel</CustomButton>
                <CustomButton onClick={logoutHandler}>Logout</CustomButton>
            </FlexBox>
        </CustomModal>
    )
}

export default Logout
