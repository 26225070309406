import clsx from "clsx";
import Style from "./index.module.scss";

type props = {
  title?: string;
  description?: string;
  className?: string;
};
export default function Dashboardtitle(Props: props) {
  const { title, description, className } = Props;
  return (
    <div className={clsx(Style.title, className)}>
      <h2> {Props.title}</h2>
      <p>{Props.description}</p>
    </div>
  );
}
