import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "../../assets/icons/Icon button.svg";
import { ReactComponent as Notifications } from "../../assets/icons/Notifications.svg";
import { ReactComponent as Avatar } from "../../assets/icons/user avatar.svg";
import style from "../../components/headerIcon/index.module.scss";
import ProfileDropdown from "../ProfileDropdown";
import { useAppSelector } from "../../store/hooks";
import Notifiaction from "../Notifiaction";

export default function HeaderIcons() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { user } = useAppSelector(state => state.auth)
  return (
    <div>
      <ul className={style.flaticon}>
        {/* <li className={style.notification}>
          <Link to="">
            <Notifications />
          </Link>
        </li>
        <li className={clsx(style.camera, style.space)}>
          <Link to="">
            <Icon />
          </Link>
        </li> */}
        <li>
          <Notifiaction />
        </li>
        <li className={clsx(style.avatar, style.space)}>
          <button className={style.logOut_avatar} onClick={handleClick} data-cy="avatar">
            {user?.image ? <img src={user?.image} /> : <Avatar />}
          </button>
        </li>
      </ul>
      <ProfileDropdown
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </div>
  );
}
