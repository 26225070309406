import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { Menu, MenuItem } from "@mui/material";
import styles from "./index.module.scss";
import Logout from "../Logout";
import { useState } from "react";
import { LockIcon, LogoutIcon, UserIconActive } from "../../assets";

const items = [
  { id: 1, title: "Change password", url: "/changePassword", icon: <LockIcon /> },
  { id: 2, title: "Profile", url: "/profile", icon: <UserIconActive /> },
  { id: 3, title: "Logout", url: "#logout", icon: <LogoutIcon /> },
];
type Props = {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose: () => void;
};
const paperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};
const ProfileDropdown = (props: Props) => {
  const { open, anchorEl, handleClose } = props;
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);
  const toggleLogoutModal = () =>
    setIsLogoutModalOpen((isLogoutModalOpen) => !isLogoutModalOpen);
    const navigateLogout = () => {
      toggleLogoutModal();
    };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleOnClick = (url: string) => {
    if (url == "#logout") {
      toggleLogoutModal();
    } else {
      navigate(url);
    }
    handleClose();
  };
  
  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {items.map((item) => (
          <MenuItem
            onClick={() => handleOnClick(item.url)}
            key={item.id}
            className={styles.menuItem}
            data-cy={item.title}
          >
            {item.icon}
            <span>{item.title}</span>
          </MenuItem>
        ))}
      </Menu>
      <Logout isOpen={isLogoutModalOpen} toggle={navigateLogout} />
    </>
  );
};

export default ProfileDropdown;
