import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedRow } from "../../components/DataTables";
import { GetSubadminById, SingleSubadmin } from "../../types";

type SelectUser = {
  id: number;
  admin: string;
  name: string;
  email: string;
  date: string;
  lastActive: string;
  url: string;
  action: string;
  status: string;
  user?: string;
  timezone?: string;
  firstName: string;
  lastName: string;
  timeZone?:
    | {
        value: string;
        label: string;
      }
    | undefined;
};
export interface subadminState {
  user: SelectUser | null;
  subadmin: SingleSubadmin | null;
  deleteType: string;
  isEditable: boolean;
}

const initialState: subadminState = {
  user: null,
  subadmin: null,
  deleteType: "",
  isEditable: false,
};

export const subAdminSlice = createSlice({
  name: "subadmin",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: SelectUser }>) => {
      state.user = action.payload.user;
    },
    setSubadmin: (state, action: PayloadAction<{ user: SingleSubadmin }>) => {
      state.subadmin = action.payload.user;
      state.isEditable = true;
    },
    resetSubadmin: (state) => {
      state.subadmin = null;
      state.isEditable = false;
    },
    setDeleteType: (state, action: PayloadAction<{ type: string }>): void => {
      state.deleteType = action.payload.type;
    },
  },
});

export const { setUser, setDeleteType, setSubadmin, resetSubadmin } =
  subAdminSlice.actions;

export default subAdminSlice.reducer;
