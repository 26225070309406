export const userManagementData = [
  {
    id: 1,
    user: "walt_hh",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    name: "Wakter Horton",
    accountId: "asbjb3jbjdbfkbkb23kbk",
    status: "Active",
    balance: "$3423.3",
    request: "2",
    action: "1988",
  },
  {
    id: 2,
    user: "arya_stark",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    name: "Arya Stark",
    accountId: "sdkfjbdkasf7kaksbd",
    status: "Active",
    balance: "$24343.3",
    request: "4",
    action: "1988",
  },
  {
    id: 3,
    user: "john_snow",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    name: "John Snow",
    accountId: "adk78askn8askhk",
    status: "Active",
    balance: "$65423.3",
    request: "9",
    action: "1988",
  },
  {
    id: 4,
    user: "sansa_stark",
    name: "Sansa Stark",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    accountId: "asjdbah34bk2jl43lkj",
    status: "Not active",
    balance: "$13224.3",
    request: "20",
    action: "1988",
  },
  {
    id: 5,
    user: "ragnar_lothbrok",
    name: "Ragnar Lothbrok",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    accountId: "kjn234knk23k3h4kj",
    status: "Active",
    balance: "$2384238.3",
    request: "43",
    action: "1988",
  },
  {
    id: 6,
    user: "tyrion_lan",
    name: "Tyrion Lannister",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    accountId: "zsjfhb2j3kb2kb3kbk",
    status: "Active",
    balance: "$245243.3",
    request: "24",
    action: "1988",
  },
];
export const subAdminsData = [
  {
    id: 1,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 2,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 3,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 4,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 5,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 6,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 7,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 8,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 9,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 10,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
  {
    id: 11,
    admin: "walt_hh",
    name: "Walt H",
    email: "walt@gmail.com",
    date: "10-12-2015",
    lastActive: "12-12-2016",
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    action: "action",
    status: "Active",
  },
];
export const options = [
  { value: "indian", label: "Indian" },
  { value: "pakistani", label: "Pakistani" },
  { value: "srilankan", label: "Srilankan" },
];
export const timeZone = [
  { value: "Asia/Kolkata", label: "Asia/Kolkata" },
  { value: "Asia/Delhi", label: "Asia/Delhi" },
  { value: "Asia/Russia", label: "Asia/Russia" },
  { value: "Asia/China", label: "Asia/China" },
  { value: "Asia/Srilanka", label: "Asia/Srilanka" },
  { value: "Asia/HongKong", label: "Asia/HongKong" },
];

export const requestData = [
  {
    id: 1,
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    nft: "2022",
    collection: "CryptoPunks",
    price: "18.4 ETH",
    amount: "21.211 $NEAL",
    date: "20.03.2022",
    type: "Deposit",
    action: "",
  },
  {
    id: 2,
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    nft: "2022",
    collection: "CryptoPunks",
    price: "18.4 ETH",
    amount: "21.211 $NEAL",
    date: "20.03.2022",
    type: "Deposit",
    action: "",
  },
  {
    id: 3,
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    nft: "2022",
    collection: "CryptoPunks",
    price: "18.4 ETH",
    amount: "21.211 $NEAL",
    date: "20.03.2022",
    type: "Deposit",
    action: "",
  },
  {
    id: 4,
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    nft: "2022",
    collection: "CryptoPunks",
    price: "18.4 ETH",
    amount: "21.211 $NEAL",
    date: "20.03.2022",
    type: "Deposit",
    action: "",
  },
  {
    id: 5,
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    nft: "2022",
    collection: "CryptoPunks",
    price: "18.4 ETH",
    amount: "21.211 $NEAL",
    date: "20.03.2022",
    type: "Deposit",
    action: "",
  },
  {
    id: 6,
    url: "https://wallpaperaccess.com/full/1281814.jpg",
    nft: "2022",
    collection: "CryptoPunks",
    price: "18.4 ETH",
    amount: "21.211 $NEAL",
    date: "20.03.2022",
    type: "Deposit",
    action: "",
  },
];
export const transactionData = [
  {
    id: 1,
    transaction: "Balance deposit",
    amount: "-$128.08",
    date: "10.03.2022",
    fees: "1%",
    status: "Successfull",
    amountType: "dec",
  },
  {
    id: 2,
    transaction: "Claiming",
    amount: "$1238.08",
    date: "20.04.2022",
    fees: "0%",
    status: "Successfull",
    amountType: "inc",
  },
  {
    id: 3,
    transaction: "Balance deposit",
    amount: "-$1238.08",
    date: "20.01.2022",
    fees: "2%",
    status: "Successfull",
    amountType: "dec",
  },
  {
    id: 4,
    transaction: "Balance deposit",
    amount: "$1631.08",
    date: "12.02.2022",
    fees: "1%",
    status: "Successfull",
    amountType: "inc",
  },
  {
    id: 5,
    transaction: "Claiming",
    amount: "-$128.08",
    date: "05.02.2022",
    fees: "3%",
    status: "Successfull",
    amountType: "dec",
  },
  {
    id: 6,
    transaction: "Balance deposit",
    amount: "$1468.08",
    date: "13.03.2022",
    fees: "5%",
    status: "Successfull",
    amountType: "inc",
  },
  {
    id: 7,
    transaction: "Claiming",
    amount: "-$176758.08",
    date: "25.09.2022",
    fees: "5%",
    status: "Successfull",
    amountType: "dec",
  },
  {
    id: 8,
    transaction: "Claiming",
    amount: "$12346.08",
    date: "16.08.2022",
    fees: "8%",
    status: "Successfull",
    amountType: "inc",
  },
  {
    id: 9,
    transaction: "Balance deposit",
    amount: "-$92349.08",
    date: "20.05.2022",
    fees: "11%",
    status: "Successfull",
    amountType: "dec",
  },
  {
    id: 10,
    transaction: "Claiming",
    amount: "$543.08",
    date: "20.11.2022",
    fees: "1%",
    status: "Successfull",
    amountType: "inc",
  },
];

export const IMAGE_FORMATS: string[] = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/svg",
  "image/svg+xml",
];

export const events = {
  accountDelete: "accountDelete",
  getNotification: "get-notification",
};
