import React, { Suspense, useEffect, useRef } from "react";
import Sidebar, { SideBarRef } from "../components/Sidebar";
import styles from "./index.module.scss";
import DashboardHeader from "../components/DashboardHeader";
import { Outlet, useLocation } from "react-router-dom";
import Spinner from "../components/Spinner";

export default function AppLayout() {
  const sideBarRef = useRef<SideBarRef>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const refToTop = useRef<HTMLInputElement>(null);
  const location = useLocation();

  const toggleSideBar = () => {
    sideBarRef.current?.toggleSideBar?.();
  };

  const toggleActiveClass = () => {
    if (parentRef.current) {
      parentRef.current.classList.toggle(styles.activeDashboard);
    }
  };
  useEffect(() => {
    refToTop.current && refToTop.current.scrollTo(0, 0);
  }, [location]);
  return (
    <div className={styles.layout}>
      <Sidebar ref={sideBarRef} toggleActiveClass={toggleActiveClass} />
      <div ref={parentRef}
        className={styles.mainClass}
      >
        <DashboardHeader toggleSideBar={toggleSideBar} />
        <div className={styles.content} ref={refToTop}>
          <Suspense fallback={<Spinner className={styles.spinner} />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
