import React, { useEffect, useRef, useState } from "react";
import Title from "../components/Title";
import CustomModal from "../components/common/CustomModal";
import { useSocket } from "../context/Socket";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { logoutUser } from "../store/reducers/authSlice";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactElement | React.ReactElement[];
};

export default function SessionLayout(props: Props) {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { io } = useSocket();
  const refToTop = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsSModalOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setIsSModalOpen((prev) => !prev);
  };

  useEffect(() => {
    if (user && io) {
      io.on("adminAccountDelete", async (result: { userId: string }) => {
        if (result.userId === user.id) {
          toggleModal();
          setTimeout(() => {
            dispatch(logoutUser());
            setIsSModalOpen(false);
          }, 3000);
        }
      });
    }
  }, [user]);

  return (
    <div className={styles.content} ref={refToTop}>
      <CustomModal isOpen={isModalOpen} toggle={toggleModal}>
        <Title
          className={styles.modalContent}
          title={`Account deleted!`}
          content={`Your account is deleted by admin we are logging you out`}
        />
      </CustomModal>
      {props.children}
    </div>
  );
}
