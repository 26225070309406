import { useEffect, useState } from "react";
import { useSocket } from "../../context/Socket";
import { useGetAllNotificationsQuery } from "../../service/api";
import { NotificationData, NotificationListenRes } from "../../types";
import { events } from "../../utils/constant";

type useNotificationProbs = {
  page?: number;
  search?: string;
};
const useNotification = (props: useNotificationProbs) => {
  const { page, search } = props;
  const { io } = useSocket();
  const [notifiacationData, setNotifiacationData] = useState<
    NotificationData[]
  >([]);
  const [notifiacationCount, setNotifiacationCount] = useState<number>(0);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const { data, isLoading, refetch } = useGetAllNotificationsQuery({
    page: page ?? 1,
    limit: 10,
    search: search,
  });

  useEffect(() => {
    setNotifiacationData(data?.data?.data ?? []);
    setNotifiacationCount(data?.data?.count ?? 0);
    setUnreadCount(data?.data?.unreadCount ?? 0);
  }, [search]);

  useEffect(() => {
    setNotifiacationData((prevItem) => [
      ...prevItem,
      ...(data?.data?.data ?? []),
    ]);
    setNotifiacationCount(data?.data?.count ?? 0);
    setUnreadCount(data?.data?.unreadCount ?? 0);
  }, [data?.data?.data]);

  useEffect(() => {
    io?.on(events.getNotification, (data: NotificationListenRes) => {
      setNotifiacationData(data?.data);
      setNotifiacationCount(data?.count ?? 0);
      setUnreadCount(data?.unreadCount ?? 0);
    });
  }, [io]);

  return { notifiacationData, notifiacationCount, unreadCount };
};

export default useNotification;
