import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CampaignData, TierLevelPayload, LoyaltyLevelData } from "../../types";

export interface loyaltyState {
  campaign: CampaignData | null;
  loyalityLvl: LoyaltyLevelData | null;
  isEditable: boolean;
  tierLevel: TierLevelPayload | null;
}

const initialState: loyaltyState = {
  campaign: null,
  loyalityLvl: null,
  isEditable: false,
  tierLevel: null,
};

export const loyaltyProgramSlice = createSlice({
  name: "loyaltyProgramSlice",
  initialState,
  reducers: {
    setCampaign: (state, action: PayloadAction<CampaignData>) => {
      state.isEditable = true;
      state.campaign = action.payload;
    },
    setLoyality: (state, action: PayloadAction<LoyaltyLevelData>) => {
      state.isEditable = true;
      state.loyalityLvl = action.payload;
    },
    resetLoyality: (state, action: PayloadAction<void>) => {
      state.isEditable = false;
      state.loyalityLvl = null;
    },
    resetCampaign: (state) => {
      state.isEditable = false;
      state.campaign = null;
    },
    setTier: (state, action: PayloadAction<TierLevelPayload>) => {
      state.isEditable = true;
      state.tierLevel = action.payload;
    },
    resetTier: (state) => {
      state.isEditable = false;
      state.tierLevel = null;
    },
  },
});

export const {
  setCampaign,
  resetCampaign,
  setTier,
  resetTier,
  setLoyality,
  resetLoyality,
} = loyaltyProgramSlice.actions;

export default loyaltyProgramSlice.reducer;
