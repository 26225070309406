import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "./CustomButton";

function Fallback({ error }: any) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color="#190044" variant="h3" fontWeight="bold">
        Something went wrong!
      </Typography>
      <CustomButton
        type="button"
        style={{
          width: "100%",
          maxWidth: "150px",
          fontSize: "14px",
          margin: "20px 0",
        }}
        onClick={() => window.location.reload()}
      >
        <Typography color="white">Try again</Typography>
      </CustomButton>
      <Typography>{error?.content || error?.message}</Typography>
    </Box>
  );
}

export default class ErrorBoundary extends React.Component<
  any,
  { error: any; errorInfo: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    console.log(error);
  }

  render() {
    if (this.state.errorInfo) {
      return <Fallback error={this.state.error} />;
    }
    return this.props.children;
  }
}
