import React from "react";
import { useAppSelector } from "../store/hooks";
import { Navigate } from "react-router-dom";

type Props = {
  element: React.LazyExoticComponent<() => JSX.Element> | (() => JSX.Element);
};

const Admin = (props: Props) => {
  const { element: Component } = props;
  const { user } = useAppSelector((state) => state.auth);
  if (user?.role !== "Admin") {
    return <Navigate to="/unauthorized" />;
  }
  return <Component />;
};

const SubAdmin = (props: Props) => {
  const { element: Component } = props;
  const { user } = useAppSelector((state) => state.auth);
  if (!["SubAdmin", "Admin"].includes(user?.role || "")) {
    return <Navigate to="/unauthorized" />;
  }
  return <Component />;
};

const Can = {
  Admin,
  SubAdmin,
};

export default Can;
