import clsx from "clsx";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactElement | React.ReactElement[];
  alignStart?: boolean;
  type?: string;
  className?: string;
};

const FlexBox = ({
  type,
  children,
  className,
}: Props & Omit<React.HTMLProps<HTMLButtonElement>, "type">) => {
  return (
    <div className={clsx(styles.container, !type && styles.margin, className)}>
      {children}
    </div>
  );
};
export default FlexBox;
