import { Alert, AlertColor } from "@mui/material";

type Props = {
    type: AlertColor | undefined,
    children: React.ReactNode,
    className?: string;
    onClose?: () => void
}
const AlertMessage = (props: Props) => {
    const { type, children, className, onClose } = props;
    return (
        <Alert severity={type} sx={{ marginBottom: 2 }} className={className} onClose={onClose}>{children}</Alert>
    )
}

export default AlertMessage
