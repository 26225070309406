import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import { environment } from "../environment";
import { useAppSelector } from "../store/hooks";

const Context = createContext<{ io?: Socket }>({});

export default function SocketContext(props: PropsWithChildren) {
  const { user } = useAppSelector((state) => state.auth);
  const [socket, setSocket] = useState<Socket>();

  const initSocket = () => {
    const socket: Socket = io(environment.BASE_API_URL, {
      query: {
        userId: user?.id,
      },
    });

    socket.on("connect", () => {
      console.log("Socket initialized");
    });

    socket.on("error", (err) => {
      console.log("Socket error", err);
    });

    setSocket(socket);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (user && user.id && !socket) {
        initSocket();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [user?.id, socket]);

  return (
    <Context.Provider value={{ io: socket }}>{props.children}</Context.Provider>
  );
}
export const useSocket = () => useContext(Context);
