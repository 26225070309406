import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../assets/icons/searchIcon.svg";
import style from "./index.module.scss";
import { useAppDispatch } from "../../store/hooks";
import { resetSearch, setSearch } from "../../store/reducers/commonSlice";
import { useLocation } from "react-router-dom";

export default function Searchinput() {
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [text, setText] = useState<string>("");

  const dispatch = useAppDispatch();
  const location = useLocation()

  useEffect(() => {
    dispatch(resetSearch())
    setText("")
  }, [location])

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setText(value)
    debounce(value)
  }
  const debounce = (value: string) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      dispatch(setSearch({ search: value }))
    }, 500);
    setDebounceTimeout(timeout);
  }
  return (
    <div className={style.search}>
      <SearchIcon />
      <input type="text" placeholder="Search" value={text} onChange={onChangeHandler} />
    </div>
  );
}