/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../types";
// import { User } from "../../types";

export interface newState {
  news: {
    accountId: string;
    action: string;
    createdAt: string;
    createdBy: string;
    description: string;
    id: string;
    status: string;
    title: string;
    url: string;
  } | null;
}

const initialState: newState = {
  news: null,
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state, action: PayloadAction<newState>) => {
      state.news = action.payload.news;
    },
    clearNews: (state, action: PayloadAction<void>) => {
      state.news = null;
    },
  },
});

export const { setNews, clearNews } = newsSlice.actions;

export default newsSlice.reducer;
