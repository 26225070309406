import clsx from "clsx";
import { KeyboardEventHandler, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { Done } from "../../assets";
import CustomButton from "../../components/CustomButton";
import { useOtpVerify2faMutation } from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  loginUser,
  setQRCode,
} from "../../store/reducers/authSlice";
import styles from "./index.module.scss";
import ForgotTwoFactor from "../../components/ForgotTwoFactor";
import { useAlertMessage } from "../../context/AlertContext";
import AlertMessage from "../../components/AlertMessage";
import AuthLayout from "../../layout/AuthLayout";

export default function Otp2FA() {
  const { state } = useLocation();
  const { email } = useAppSelector((state) => state.auth);

  const [otp, setOtp] = useState<string>("");
  const [response, setResponse] = useState<string>("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const [verifyOtp, { isLoading }] = useOtpVerify2faMutation();

  const onSubmit = async () => {
    if (otp.length < 6 || isLoading) return;
    const payload = {
      email,
      otp,
    };
    const { data, error }: any = await verifyOtp(payload);
    if (data) {
      const { user, token, refreshToken = "" } = data?.data;
      dispatch(loginUser({ token, refreshToken }));
      if (user) {
        dispatch(setQRCode(data?.qrCode2FA ?? ""));
        return navigate("/", {
          state: data,
          replace: true,
        });
      } else {
        return navigate("/2fa-auth", {
          state: data,
          replace: true,
        });
      }
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error.data.message
      });
    }
  };

  const isLetter = () => {
    if (otp.match(/[a-z]/i) === null) {
      return false;
    } else {
      return true;
    }
  };
  const enterKeyHandler: KeyboardEventHandler<HTMLFormElement> = (e) => {
    if (e.key === "Enter") {
      if (otp.length < 6) return;
      onSubmit();
    }
  };
  const forgotUi = () => {
    return (
      <div className={styles.forgotUI}>
        <Done />
        <h1>Congratulations</h1>
        <p>{response}</p>
        <div className={styles.loginBtnContainer}>
          <CustomButton onClick={() => navigate("/login", { replace: true })}>
            Login
          </CustomButton>
        </div>
      </div>
    );
  };
  return (
    <AuthLayout>
      <div className={styles.sign_form}>
        {response ? (
          forgotUi()
        ) : (
          <div>
            <h2 className={styles.create}>Verify 2FA Authentication</h2>
            <p className={styles.createContent}>
              Please enter 6-digit code we send to your authenticator.
            </p>
            {alertMsg?.msg && <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>{alertMsg.msg}</AlertMessage>}

            <form onKeyDown={enterKeyHandler} className={styles.verify_code}>
              <OTPInput
                onChange={setOtp}
                value={otp}
                inputStyle="inputStyle"
                containerStyle={styles.reactOtp}
                numInputs={6}
                shouldAutoFocus={true}
                renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                renderInput={(props) => {
                  return (
                    <input
                      {...props}
                      type="number"
                      value={props.value}
                      className={styles.inputOtp}
                      placeholder="0"
                    />
                  );
                }}
              />
            </form>
            <div
              className={clsx(
                styles.input_group,
                styles.login,
                styles.submit
              )}
            >
              <CustomButton
                disabled={otp.length !== 6 || isLetter()}
                onClick={onSubmit}
              >
                {isLoading ? "Loading..." : "Submit"}
              </CustomButton>
              {state && (
                <ForgotTwoFactor user={state} setResponse={setResponse} />
              )}
            </div>
          </div>
        )}
      </div>
    </AuthLayout>
  );
}
