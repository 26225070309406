import clsx from "clsx";
import React from "react";
import style from "./index.module.scss";

type Props = {
  children: React.ReactElement;
  padding?: string;
  className?: string;
};

export default function Card(props: Props) {
  const { className, children, padding = "lg" } = props;
  return (
    <div className={clsx(style.card, className, style[padding])}>
      {children}
    </div>
  );
}
