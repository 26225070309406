import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SavingPlan } from "../../types";

export interface loyaltyState {
  savingPlan: SavingPlan | null;
  isEditable: boolean;
}

const initialState: loyaltyState = {
  savingPlan: null,
  isEditable: false,
};

export const savingPlanSlice = createSlice({
  name: "savingPlanSlice",
  initialState,
  reducers: {
    setSavingPLan: (state, action: PayloadAction<SavingPlan>) => {
      state.isEditable = true;
      state.savingPlan = action.payload
    },
    resetSavingPLan: (state) => {
      state.isEditable = false;
      state.savingPlan = null
    },
  },
});

export const { setSavingPLan, resetSavingPLan } = savingPlanSlice.actions;

export default savingPlanSlice.reducer;
