import { useNavigate } from "react-router-dom";
import CustomButton from "../CustomButton";
import Title from "../Title";
import style from "./index.module.scss";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className={style.not_found}>
      <div className={style.wrapper}>
        <h1>404</h1>
        <Title title={`Page not Found`} className={style.title} />
        <CustomButton
          type="button"
          className="tyrAgain"
          onClick={() => navigate("/")}
        >
          Take me home
        </CustomButton>
      </div>
    </div>
  );
};

export default NotFound;
