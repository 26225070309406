import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { environment } from "../environment";
import { logoutUser, setTokens } from "../store/reducers/authSlice";
import { RootState } from "../store/store";

import {
  GetUserById,
  DeleteAdmin,
  EditSubadmin,
  ResetPassword,
  loginResponse,
  SavingPlanArray,
  Campaign,
  CampaignRes,
  apiResponse,
  getCampaign,
  CampaignData,
  TierLevelPayload,
  resp,
  PasswordChange,
  PasswordChangeRes,
  GetSubadminById,
  getLoyaltyLevel,
  LoyaltyLevelRes,
  LoyaltyLevel,
  FilterProps,
  AcceptDeclineProps,
  AffiliateReq,
  AccountStatusResponse,
  EditAccountStatusResponse,
  Otp2FA,
  NewsManagementData,
  News,
  DeleteNews,
  Disable2FA,
  Auth2FA,
  UpdateProfile,
  User,
  ProfileResp,
  ResetPasswordResp,
  PasswordResetResp,
  NotificationRes,
  NotificationRequest,
  ReadNotification,
  AcceptDeclineReactivationProps,
  AppConfigs,
  CheckUsername,
  CheckUserNameRes,
} from "../types";

export type Email = {
  email: string;
  password?: string;
};
export type ResetPasswordType = {
  password: string;
  confirmPassword?: string;
};

export type AddSubAdmin = {
  id?: string;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword?: string | undefined;
};

export type AllUser = {
  page: number;
  search: string;
  sort?: number | undefined;
  query?: number;
  status?: number;
};

export type UserManagement = User;

export type UserManagementData = {
  data: {
    status: number;
    data: UserManagement[];
    count: number;
  };
  message: string;
  success: boolean;
  length: number;
  error: {
    status: number;
    data: {
      message: string;
    };
  };
  status: number;
};

type ApiResponse<T> = {
  data: T;
  message?: string;
};

export type ManageUser = {
  id: number | string | undefined;
  countryOfResidence: string;
  dob: string;
  email: string;
  nationality: string;
  phone: string;
  timeZone: string;
  userName: string;
};
export type EditUser = {
  id: number | string | undefined;
  user: ManageUser;
};
type UserID = {
  userId: (string | number | undefined)[] | undefined;
};

export type AddSavingPlan = {
  id?: string;
  title: string;
  token?: string | undefined;
  duration?: string;
  description: string;
  average?: string;
};

export type AllSavingPlan = {
  search: string;
  status: string;
};

export type AllCampain = {
  search: string;
};

export type GetLoyaltyLevel = {
  search: string;
};

export type GetTierLevel = {
  search: string;
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${environment.BASE_API_URL}/api/admin`,
  prepareHeaders: async (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

type GetAccessTokenApiResponse = {
  data: { accessToken: string; refreshToken: string };
};
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const { data } = await baseQuery(
      {
        url: `${environment.BASE_API_URL}/api/user/get-access-token`,
        method: "POST",
        body: {
          refreshToken: localStorage.getItem("refreshToken"),
          type: "admin",
        },
      },
      api,
      extraOptions
    );
    const tokenResult = data as GetAccessTokenApiResponse;
    if (tokenResult && tokenResult.data) {
      // store the new token
      api.dispatch(setTokens(tokenResult.data as any));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logoutUser());
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: ["AccountStatus", "profile", "campaign"],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUsers: builder.query<any, string>({
      query: () => `/users`,
    }),
    login: builder.mutation<loginResponse, Email>({
      query: (body) => ({
        url: `/login`,
        method: "POST",
        body: body,
      }),
    }),
    forgotPassword: builder.mutation<any, Email>({
      query: (body) => ({
        url: `/forgot-password`,
        method: "POST",
        body: body,
      }),
    }),
    resetPassword: builder.mutation<any, ResetPassword>({
      query: (body) => ({
        url: `/reset-password`,
        method: "POST",
        body: body,
      }),
    }),
    getAllUsers: builder.query<UserManagementData, AllUser>({
      query: ({ page, search, query }) => {
        const searchValue = encodeURIComponent(search);
        return {
          url: `/users?page=${
            page ?? 1
          }&search=${searchValue}&sorting=${query}`,
        };
      },
    }),
    getUserById: builder.query<GetUserById, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      // transformResponse: (response, meta, arg) => response?.data?.data
    }),
    getSubAdmin: builder.query<UserManagementData, AllUser>({
      query: ({ page, search, sort }) =>
        `/subadmin?page=${page ?? 1}&search=${search}&sorting=${sort}`,
    }),
    getSubadminById: builder.query<GetSubadminById, string>({
      query: (id) => ({
        url: `/subadmin?id=${id}`,
      }),
      // transformResponse: (response, meta, arg) => response?.data?.data
    }),
    addSubAdmin: builder.mutation<UserManagementData, { email: string }>({
      query: (body) => ({
        url: `/subadmin`,
        method: "POST",
        body: body,
      }),
    }),
    addSubAdminDetails: builder.mutation<UserManagementData, AddSubAdmin>({
      query: ({ id, ...rest }) => ({
        url: `/subadmin-set-password/${id}`,
        method: "PUT",
        body: rest,
      }),
    }),
    editSubAdmin: builder.mutation<UserManagementData, EditSubadmin>({
      query: ({ id, ...rest }) => ({
        url: `/subadmin/${id}`,
        method: "PUT",
        body: rest,
      }),
    }),
    editStatusSubAdmin: builder.mutation<UserManagementData, EditSubadmin>({
      query: ({ id, ...rest }) => ({
        url: `/subadmin/${id}`,
        method: "PATCH",
        body: rest,
      }),
    }),
    deleteSubAdmin: builder.mutation<UserManagementData, DeleteAdmin>({
      query: (userId) => ({
        url: `/subadmin`,
        method: "DELETE",
        body: userId,
      }),
    }),
    editUser: builder.mutation<UserManagementData, ManageUser>({
      query: ({ id, ...user }) => ({
        url: `/user/${id}`,
        method: "PUT",
        body: user,
      }),
    }),
    deleteUser: builder.mutation<UserManagementData, UserID>({
      query: (body) => ({
        url: `/users`,
        method: "DELETE",
        body: body,
      }),
    }),
    getCountry: builder.query<UserManagementData, UserID>({
      query: () => `/country-list`,
    }),
    getSavingPlans: builder.query<SavingPlanArray, AllSavingPlan>({
      query: ({ search, status }) => `/plans?status=${status}&search=${search}`,
    }),
    deletePlan: builder.mutation<UserManagementData, { userId: string }>({
      query: ({ userId }) => ({
        url: `/plans/${userId}`,
        method: "DELETE",
      }),
    }),
    addSavingPlan: builder.mutation<UserManagementData, AddSavingPlan>({
      query: (body) => ({
        url: `/plans`,
        method: "POST",
        body: body,
      }),
    }),
    activatePlan: builder.mutation<UserManagementData, { userId: string }>({
      query: ({ userId }) => ({
        url: `/plans/${userId}`,
        method: "PATCH",
      }),
    }),
    editSavingPlan: builder.mutation<UserManagementData, AddSavingPlan>({
      query: ({ id, ...rest }) => ({
        url: `/plans/${id}`,
        method: "PUT",
        body: rest,
      }),
    }),
    getCampaign: builder.query<getCampaign, AllCampain>({
      query: ({ search }) => `/campaign?search=${search}`,
      providesTags: ["campaign"],
    }),
    addCampaign: builder.mutation<CampaignRes, Campaign>({
      query: (body) => ({
        url: `/campaign`,
        method: "POST",
        body,
      }),
    }),
    editCampaignFc: builder.mutation<CampaignRes, Campaign>({
      query: ({ id, ...rest }) => ({
        url: `/campaign/${id}`,
        method: "PUT",
        body: rest,
      }),
    }),
    deleteCampaignFc: builder.mutation<CampaignRes, { id: string | undefined }>(
      {
        query: ({ id }) => ({
          url: `/campaign/${id}`,
          method: "DELETE",
        }),
      }
    ),
    getTier: builder.query<resp, void>({
      query: () => `/tierLevel`,
      // transformResponse: (response: unknown): TierLevelPayload => {
      //   return response.data;
      // },
    }),
    addTier: builder.mutation<CampaignRes, TierLevelPayload>({
      query: (body) => ({
        url: `/tierLevel`,
        method: "POST",
        body,
      }),
    }),
    updateStatusCampaignFc: builder.mutation<
      CampaignRes,
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `/campaign/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["campaign"],
    }),
    changePassword: builder.mutation<PasswordChangeRes, PasswordChange>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/change-password`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    getLoyaltyLevel: builder.query<getLoyaltyLevel, GetLoyaltyLevel>({
      query: ({ search }) => `/loyaltyLevel?search=${search}`,
    }),
    deleteLoyaltyLevelFc: builder.mutation<
      LoyaltyLevelRes,
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `/loyaltyLevel/${id}`,
        method: "DELETE",
      }),
    }),
    addLoyaltyLevelFc: builder.mutation<LoyaltyLevelRes, LoyaltyLevel>({
      query: (body) => ({
        url: `/loyaltyLevel`,
        method: "POST",
        body,
      }),
    }),
    deleteTier: builder.mutation<CampaignRes, string | undefined | null>({
      query: (id) => ({
        url: `/tierLevel/${id}`,
        method: "DELETE",
      }),
    }),
    editTier: builder.mutation<CampaignRes, TierLevelPayload>({
      query: (body) => ({
        url: `/tierLevel/${body.id}`,
        method: "PUT",
        body,
      }),
    }),
    editLoyaltyLevelFc: builder.mutation<LoyaltyLevelRes, LoyaltyLevel>({
      query: ({ id, ...rest }) => ({
        url: `/loyaltyLevel/${id}`,
        method: "PUT",
        body: rest,
      }),
    }),
    getAffiliateUser: builder.query<getLoyaltyLevel, FilterProps>({
      query: ({ page, limit }) => `/affiliate?page=${page}&pageLimit=${limit}`,
    }),
    acceptDeclineAffiliate: builder.mutation<
      LoyaltyLevelRes,
      AcceptDeclineProps
    >({
      query: (body) => ({
        url: `/affiliate`,
        method: "PATCH",
        body,
      }),
    }),
    acceptDeclineGdpr: builder.mutation<LoyaltyLevelRes, AcceptDeclineProps>({
      query: (body) => ({
        url: `/gdpr-request`,
        method: "PATCH",
        body,
      }),
    }),
    // /admin/gdpr-request
    affiliateRequest: builder.query<getLoyaltyLevel, AffiliateReq>({
      query: ({ type, page, limit, column, sorttype }) =>
        `/affiliate?type=${type}&page=${page}&pageLimit=${limit}&column=${column}&sorttype=${sorttype}`,
    }),
    gdprRequest: builder.query<getLoyaltyLevel, AffiliateReq>({
      query: ({ type, page, limit, column, sorttype }) =>
        `/gdpr-request?requestType=${type}&page=${page}&pageLimit=${limit}&column=${column}&sorttype=${sorttype}`,
    }),
    getAccountStatus: builder.query<AccountStatusResponse, void>({
      query: () => `/account-status`,
      providesTags: ["AccountStatus"],
      //transformResponse:(response: AccountStatusResponse[])=>{}
    }),
    editAccountStatus: builder.mutation<
      EditAccountStatusResponse,
      AcceptDeclineProps
    >({
      query: ({ id, ...rest }) => ({
        url: `/account-status/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["AccountStatus"],
    }),
    otpVerify2fa: builder.mutation<any, Otp2FA>({
      query: (body) => {
        return {
          url: `/2fa`,
          method: "POST",
          body: body,
        };
      },
    }),
    getNews: builder.query<NewsManagementData, AllUser>({
      query: ({ page, search, sort }) =>
        `/news?page=${page ?? 1}&search=${search}&sorting=${sort}`,
    }),
    addNews: builder.mutation<News, any>({
      query: (formData) => {
        // const { formData } = body;
        return {
          url: `/news`,
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteNews: builder.mutation<News, DeleteNews>({
      query: (body) => {
        return {
          url: `/news`,
          method: "DELETE",
          body: body,
        };
      },
    }),
    editNews: builder.mutation<News, any>({
      query: ({ id, formData }) => {
        // const { id , ...rest } = body;
        // console.log('>>>>>>  id', body)
        return {
          url: `/news/${id}`,
          method: "PUT",
          body: formData,
        };
      },
    }),
    disable2FA: builder.mutation<apiResponse, Disable2FA>({
      query: (body) => {
        return {
          // url: `/disable2fa/${body.id}`,
          url: `/disable2fa-email`,
          method: "POST",
          body: body.data,
        };
      },
    }),
    enableDisable2fa: builder.mutation<UserManagementData, Auth2FA>({
      query: (body) => {
        const { userId, ...rest } = body;
        return {
          url: `/2fa-auth`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    disable2FAUser: builder.mutation<UserManagementData, EditSubadmin>({
      query: ({ id, ...rest }) => ({
        url: `/disable2fa/${id}`,
        method: "PUT",
        body: rest,
      }),
    }),
    getProfile: builder.query<ProfileResp, void>({
      query: () => `/profile`,
      providesTags: ["profile"],
    }),
    updateProfile: builder.mutation<UserManagementData, FormData>({
      query: (body) => ({
        url: `/profile-update`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["profile"],
    }),
    deleteProfilePic: builder.mutation<UserManagementData, void>({
      query: () => ({
        url: `/profile-img`,
        method: "DELETE",
      }),
      invalidatesTags: ["profile"],
    }),
    resendEmailSubAdmin: builder.mutation<UserManagementData, EditSubadmin>({
      query: ({ id, ...rest }) => ({
        url: `/subadmin/resend-email/${id}`,
        method: "POST",
        body: rest,
      }),
    }),
    addDepositAddresses: builder.mutation<null, FormData>({
      query: (body) => ({
        url: `/deposit-addresses`,
        method: "POST",
        body: body,
      }),
    }),
    setDepositAddress: builder.mutation<null, string>({
      query: (userId) => ({
        url: `/deposit-addresses/${userId}`,
        method: "PATCH",
      }),
    }),
    getDepositAddressesCount: builder.query<{ count: number }, void>({
      query: () => "/deposit-addresses",
    }),
    getProfileSetupDetail: builder.query<ResetPasswordResp, { id: string }>({
      query: ({ id }) => `/check-profile-setup/${id}`,
    }),
    getValidatePasswordResetLink: builder.query<
      PasswordResetResp,
      { id: string }
    >({
      query: ({ id }) => `/check-reset-link/${id}`,
    }),
    getAllNotifications: builder.query<NotificationRes, NotificationRequest>({
      query: ({ limit, page, search = "" }) =>
        `/notification?pageLimit=${limit}&page=${page}&search=${search}&type=admin`,
    }),
    readNotification: builder.mutation<NotificationRes, ReadNotification>({
      query: (ids) => ({
        url: `/notification`,
        method: "PATCH",
        body: ids,
      }),
    }),
    accountRestoreRequest: builder.query<getLoyaltyLevel, AffiliateReq>({
      query: ({ type, page, limit, column }) =>
        `/restore-requests?requestType=${type}&page=${page}&pageLimit=${limit}&column=${column}`,
    }),
    acceptDeclineReactivationAccount: builder.mutation<
      LoyaltyLevelRes,
      AcceptDeclineReactivationProps
    >({
      query: (body) => ({
        url: `/restore-user-account`,
        method: "POST",
        body,
      }),
    }),
    updateAppConfigs: builder.mutation<
      ApiResponse<AppConfigs>,
      Partial<AppConfigs>
    >({
      query: (body) => ({
        url: `/app-configs`,
        method: "PUT",
        body,
      }),
    }),
    getAppConfigs: builder.query<ApiResponse<AppConfigs>, void>({
      query: () => "/app-configs",
    }),
    checkAdminValidUsername: builder.mutation<
      ApiResponse<CheckUserNameRes>,
      CheckUsername
    >({
      query: (body) => ({
        url: `/subadmin/validate-username`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAppConfigsQuery,
  useUpdateAppConfigsMutation,
  useGetUsersQuery,
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGetAllUsersQuery,
  useGetSubAdminQuery,
  useGetSubadminByIdQuery,
  useAddSubAdminMutation,
  useAddSubAdminDetailsMutation,
  useDeleteSubAdminMutation,
  useEditSubAdminMutation,
  useEditStatusSubAdminMutation,
  // useEditUsersMutation
  useEditUserMutation,
  useDeleteUserMutation,
  useGetCountryQuery,
  useGetUserByIdQuery,
  useGetSavingPlansQuery,
  useDeletePlanMutation,
  useAddSavingPlanMutation,
  useActivatePlanMutation,
  useEditSavingPlanMutation,
  useGetCampaignQuery,
  useAddCampaignMutation,
  useEditCampaignFcMutation,
  useDeleteCampaignFcMutation,
  useAddTierMutation,
  useGetTierQuery,
  useDeleteTierMutation,
  useEditTierMutation,
  useUpdateStatusCampaignFcMutation,
  useChangePasswordMutation,
  useGetLoyaltyLevelQuery,
  useDeleteLoyaltyLevelFcMutation,
  useAddLoyaltyLevelFcMutation,
  useEditLoyaltyLevelFcMutation,
  useGetAffiliateUserQuery,
  useAcceptDeclineAffiliateMutation,
  useAffiliateRequestQuery,
  useGetAccountStatusQuery,
  useEditAccountStatusMutation,
  useDisable2FAUserMutation,
  useGdprRequestQuery,
  useOtpVerify2faMutation,
  useGetNewsQuery,
  useAddNewsMutation,
  useDeleteNewsMutation,
  useEditNewsMutation,
  useAcceptDeclineGdprMutation,
  useDisable2FAMutation,
  useEnableDisable2faMutation,
  useUpdateProfileMutation,
  useGetProfileQuery,
  useDeleteProfilePicMutation,
  useResendEmailSubAdminMutation,
  useAddDepositAddressesMutation,
  useSetDepositAddressMutation,
  useGetDepositAddressesCountQuery,
  useGetProfileSetupDetailQuery,
  useGetValidatePasswordResetLinkQuery,
  useGetAllNotificationsQuery,
  useReadNotificationMutation,
  useAccountRestoreRequestQuery,
  useAcceptDeclineReactivationAccountMutation,
  useCheckAdminValidUsernameMutation,
} = api;
