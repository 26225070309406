import InfiniteScroll from "react-infinite-scroll-component";
import NotificationCardView from "./NotificationCardView";

type Notifacations = {
  id: string;
  title: string;
  message: string;
  createdTimeStamp: string;
  isRead: boolean;
};
type Props = {
  notification: Notifacations[];
  onClick?: () => void;
  fetchMoreData: () => void;
  notifiacationCount: number;
  currentPage: number;
};
export default function NotifacationMessages(props: Props) {
  const {
    notification,
    onClick,
    fetchMoreData,
    notifiacationCount,
    currentPage,
  } = props;

  const totalPage = Math.ceil(
    notifiacationCount && notifiacationCount > 10 ? notifiacationCount / 10 : 1
  );

  return (
    <div>
      <div id="scrollableDiv" style={{ height: 300, overflow: "auto" }}>
        <InfiniteScroll
          dataLength={notifiacationCount}
          next={fetchMoreData}
          hasMore={totalPage > currentPage ? true : false}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {notification.map((data, index) => {
            return (
              <NotificationCardView
                data={data}
                index={index}
                onClick={onClick}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
}
