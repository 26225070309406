import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

type SelectUser = {
  id: string;
  admin: string;
  name: string;
  user: string;
  email: string;
  date: string;
  lastActive: string;
  nationality: string;
  countryOfResidence: string;
  url: string;
  action: string;
  status: string;
  dob: string;
  timeZone: string;
};
type countryDetail = {
  name: string;
  code: string;
  dial_code: string;
};
export interface subadminState {
  user: SelectUser | null;
  users: SelectUser[] | null;
  isEditable: boolean;
  country: countryDetail | null;
  deleteType?: string;
  loading: boolean;
  count?: number;
}

const initialState: subadminState = {
  user: null,
  isEditable: false,
  country: null,
  deleteType: "",
  users: null,
  loading: false,
  count: 0,
  // error: null
};
type Props = {
  page: number;
  search: string;
  query: number;
};
// export const fetchPosts = createAsyncThunk('userManagement/fetch', async ({page, search, query}:Props) => {
//   const response = await fetch(`https://b738-103-148-153-88.ngrok-free.app/api/admin/get-all-user?page=${page}&search=${search}&sorting=${query}`);
//   const data = await response.json();
//   console.log(data.data,"resssssd")
//   return data.data;
// })

export const userManagementSlice = createSlice({
  name: "userManagementSlice",
  initialState,
  reducers: {
    setUserManage: (state, action: PayloadAction<{ user: SelectUser }>) => {
      state.isEditable = true;
      state.user = action.payload.user;
    },
    setDeleteType: (state, action: PayloadAction<{ type: string }>): void => {
      state.deleteType = action.payload.type;
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(fetchPosts.pending, (state, action) => {
  //       state.loading = true
  //     })
  //     .addCase(fetchPosts.fulfilled, (state, action) => {
  //       console.log(action.payload,"redux here")
  //       state.users = action.payload.data;
  //       state.count = action.payload.count;
  //       state.loading = false
  //     })
  //     .addCase(fetchPosts.rejected, (state, action) => {
  //       // state.status = 'failed'
  //       // state.error = action.error.message
  //     })
  // }
});

export const { setUserManage, setDeleteType } = userManagementSlice.actions;

export default userManagementSlice.reducer;
