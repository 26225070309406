import React from "react";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { useNavigate } from "react-router";
import styles from "./index.module.scss";
import CustomButton from "../../../components/CustomButton";


const UnautorizeAccess = () => {
  const navigate = useNavigate();

    return(
        <div>
            <center>
            <div>
                <Logo />
            </div >
            <h3 >You are not allowed to access</h3>
 
            {/* <button onClick={()=> navigate(-1)}>Return</button> */}
            <CustomButton
            className={styles.addBtn}
            onClick={() => {
                navigate(-1)
            }}
          >
            Return
          </CustomButton>
            </center>
        </div>
    )
};

export default UnautorizeAccess;
