import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { Controller, FieldError, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import Input from "../../components/Input";
import { Email, useLoginMutation } from "../../service/api";
import { useAppDispatch } from "../../store/hooks";
import { loginSchema } from "./yupSchema";
import styles from "./index.module.scss";
import { loginUser, setEmail, setQRCode } from "../../store/reducers/authSlice";
import { loginResponse } from "../../types";
import AuthLayout from "../../layout/AuthLayout"; 
import { useAlertMessage } from "../../context/AlertContext";
import AlertMessage from "../../components/AlertMessage";

const Login = () => {
  const [login, { isLoading: loading }] = useLoginMutation();
  const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const { email, password } = methods.formState.errors;

  const emailLoginHandler = async (e: Email) => {
    const { email, password } = e;
    const { data, error } = (await login({ email, password })) as loginResponse;
    if (data) {
      if (data?.data?.user?.is2FAEnable) {
        dispatch(setEmail(data?.data.user?.email));
        if (data?.data?.user?.qrCode2FA) {
          dispatch(setQRCode(data?.data.user?.qrCode2FA));
          navigate(`/2fa-qrcode`);
        } else {
          navigate(`/2fa-auth`, { state: { email, id: data?.data?.user?.id } });
        }
      } else {
        dispatch(loginUser(data.data));
      }
    }
    if (error) {
      setAlertMsg?.({
        type: "error",
        msg: error?.data?.message,
      });
    }
  };
  return (
    <AuthLayout title="Sign in to your admin account">
      <>
        <form onSubmit={methods.handleSubmit(emailLoginHandler)}>
          <h2>Sign in</h2>
          {alertMsg?.msg && (
            <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>
              {alertMsg.msg}
            </AlertMessage>
          )}
          <div className={styles.input_group}>
            <Controller
              control={methods.control}
              name="email"
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter username or email"
                  id="Email"
                  label="Username or email"
                  error={email as FieldError}
                  {...field}
                // inputRef={null}
                />
              )}
            />
          </div>
          <div className={styles.input_group}>
            <Controller
              control={methods.control}
              name="password"
              render={({ field }) => (
                <Input
                  type="password"
                  placeholder="Enter password"
                  id="password"
                  label="Password"
                  error={password as FieldError}
                  {...field}
                // inputRef={null}
                />
              )}
            />
          </div>
          <div
            className={styles.resetPass}
            onClick={() =>
              navigate("/reset-password", {
                state: { email: methods.getValues("email") },
              })
            }
          >
            <p data-cy="resetPassword">Reset password</p>
          </div>
          <div className={clsx(`${styles.input_group} ${styles.login}`)}>
            <CustomButton
              variant="primary"
              type="submit"
              disabled={!methods.formState.isValid}
              loading={loading}
            >
              Sign In
            </CustomButton>
          </div>
        </form>
      </>
    </AuthLayout>
  );
};

export default Login;
