import { useState } from "react";
import { Link } from "react-router-dom";
import { CloseIcon } from "../../assets";
import { ReactComponent as Notifications } from "../../assets/icons/Notifications.svg";
import useNotification from "../../Hooks/useNotification";
import Dashboardtitle from "../DashbordTitle";
import Input from "../Input";
import NotifacationMessages from "../NotifiactionPopUp";
import CustomModal from "../common/CustomModal";
import style from "./index.module.scss";
import clsx from "clsx";

export default function Notifiaction() {
  const [notification, setNotifiacation] = useState(false);
  const openNotification = () => {
    setNotifiacation((notification) => !notification);
  };

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
  };
  const { notifiacationData, notifiacationCount, unreadCount } =
    useNotification({
      page: currentPage,
      search: search.trim(),
    });

  return (
    <div>
      <div className={clsx(unreadCount > 0 ? style.unreadNotification : "", style.wrapper)} onClick={openNotification}>
          <Notifications />
      </div>
      <CustomModal
        isOpen={notification}
        toggle={openNotification}
        classNames={{ modal: style.notificationPopUp }}
      >
        <CloseIcon
          className={style.crossIcon}
          onClick={() => {
            setNotifiacation(false);
          }}
        />
        <Dashboardtitle className={style.NotificationsTitle} title="Notifications" />
        <div className={style.notificationInput}>
          <Input
            placeholder="Notifications"
            type="text"
            name="notifications"
            id="notifications"
            onChange={(e) => setSearch(e.target.value)}
          />
          <button className={style.clearAll}>Clear All</button>
        </div>
        <NotifacationMessages
          notification={notifiacationData ?? []}
          notifiacationCount={notifiacationCount}
          fetchMoreData={fetchMoreData}
          currentPage={currentPage}
        />
      </CustomModal>
    </div>
  );
}
