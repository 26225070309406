import { configureStore } from "@reduxjs/toolkit";
import { api } from "../service/api";
import authSlice from "./reducers/authSlice";
import subAdminSlice from "./reducers/subAdminSlice";
import userManagementSlice from "./reducers/userManagementSlice";
import loyaltyProgramSlice from "./reducers/loyaltyProgramSlice";
import commonSlice from "./reducers/commonSlice";
import savingPlanSlice from "./reducers/savingPlanSlice";
import newsSlice from "./reducers/newsSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authSlice,
    subAdmin: subAdminSlice,
    userManage: userManagementSlice,
    loyalty: loyaltyProgramSlice,
    savingPlan: savingPlanSlice,
    news: newsSlice,
    common: commonSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
