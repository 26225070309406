import clsx from "clsx";
import dayjs from "dayjs";
import { LetterUnread } from "../../assets";
import { useReadNotificationMutation } from "../../service/api";
import style from "./index.module.scss";
import relativeTime from 'dayjs/plugin/relativeTime';


type Notifacations = {
  id: string;
  title: string;
  message: string;
  createdTimeStamp: string;
  isRead: boolean;
};

type notifiacationCardProbs = {
  index: number;
  onClick?: () => void;
  data: Notifacations;
};

const NotificationViewCard = (props: notifiacationCardProbs) => {
  const { index, onClick, data } = props;
  dayjs.extend(relativeTime)

  const [readNotification, { isLoading: loading }] =
    useReadNotificationMutation();
  const readNotificationHandler = async (id: string) => {
    const payload = {
      ids: [id],
      type: "admin"
    };
    try {
      const { data } = await readNotification(payload).unwrap();
    } catch (error: any) {
    }
  };

  return (
    <div className={clsx(style.notification)} key={index} onClick={onClick}>
      <div className={style.icons}>
        <LetterUnread />
      </div>
      <div className={style.notificationText}>
        <h5 className={style.subTitle}>{data?.title}</h5>
        <p className={style.content}>{data?.message}</p>
        <span className={style.days}>
          {dayjs(data?.createdTimeStamp).fromNow()}
        </span>
      </div>
    </div>
  );
};

export default NotificationViewCard;
