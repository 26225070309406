import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldError, useForm } from "react-hook-form";
import Card from "../../components/Card";
import CustomButton from "../../components/CustomButton";
import Dashboardtitle from "../../components/DashbordTitle";
import Input from "../../components/Input";
import { useChangePasswordMutation } from "../../service/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import styles from "./index.module.scss";
import { PasswordChange, apiResponse } from "../../types";
import { changePasswordSchema } from "../Login/yupSchema";
import { logoutUser } from "../../store/reducers/authSlice";
import { useAlertMessage } from "../../context/AlertContext";
import AlertMessage from "../../components/AlertMessage";
const ChangePassword = () => {
    const [changePassword, { isLoading }] = useChangePasswordMutation();
    const { alertMsg, setAlertMsg, onCloseAlert } = useAlertMessage()
    const { user } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const methods = useForm({
        defaultValues: {
            oldPassword: "",
            password: "",
            confirmPassword: "",
        },
        resolver: yupResolver(changePasswordSchema)
    })
    const { errors } = methods.formState
    const changePasswordHandler = async (formData: PasswordChange) => {
        const payload = {
            userId: user?.id,
            oldPassword: formData.oldPassword,
            password: formData.password
        }
        const { data, error } = await changePassword(payload) as apiResponse
        if (data?.success === true) {
            setAlertMsg?.({
                type: "success",
                msg: data?.message
            });
            methods.reset();
            dispatch(logoutUser());
            setAlertMsg?.({
                type: "info",
                msg: "Please login again with your new password!"
            });
        }
        if (error) {
            setAlertMsg?.({
                type: "error",
                msg: error?.data?.message
            });
        }
    }
    return (
        <div >
            <Dashboardtitle title="Change Password" />
            {alertMsg?.msg && <AlertMessage type={alertMsg?.type} onClose={onCloseAlert}>{alertMsg.msg}</AlertMessage>}
            <Card className={styles.changePassword}>
                <div className={styles.inner}>
                    <form onSubmit={methods.handleSubmit(changePasswordHandler)}>
                        <Controller
                            control={methods.control}
                            name="oldPassword"
                            render={({ field }) => (
                                <Input
                                    type="password"
                                    placeholder="Enter old password"
                                    id="oldPassword"
                                    label="Old Password"
                                    error={errors.oldPassword as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                        <Controller
                            control={methods.control}
                            name="password"
                            render={({ field }) => (
                                <Input
                                    type="password"
                                    placeholder="Enter new password"
                                    id="password"
                                    label="New Password"
                                    error={errors.password as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                        <Controller
                            control={methods.control}
                            name="confirmPassword"
                            render={({ field }) => (
                                <Input
                                    type="password"
                                    placeholder="Enter confirm password"
                                    id="confirmPassword"
                                    label="Confirm Password"
                                    error={errors.confirmPassword as FieldError}
                                    {...field}
                                // inputRef={null}
                                />
                            )}
                        />
                        <ul className="password-rule">
                            <p>Password must:</p>
                            <li>- include lower and upper case</li>
                            <li>- include atleast 1 number or symbol</li>
                            <li>- be at least 8 characters long</li>
                            <li>- match in both the fields</li>
                            <li>- canot contain spaces and "|" symbol</li>
                        </ul>
                        <div className={styles.btnContainer}>
                            <CustomButton className={styles.btn} type="submit" loading={isLoading}>Submit</CustomButton>
                        </div>
                    </form>
                </div>
            </Card>
        </div>
    )
}

export default ChangePassword
